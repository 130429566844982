import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import Seo from '../components/seo'
import Layout from '../components/layout'
import * as styles from './blog-post.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

class EventTemplate extends React.Component {


  
  render() {
    
    const post = get(this.props, 'data.contentfulEvenementData')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(post.eventDescription.raw)
    )


    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.eventName}
          description={plainTextDescription}
          image={`http:${post.eventImage?.resize.src}`}
        />
        <div className='empty_box'></div>
        <GatsbyImage style={{maxWidth: '55rem', margin: '0 auto'}} image={post.eventImage?.gatsbyImageData}/>

        <div className={styles.container}>

          <div className={styles.article}>
            <h1>{post.eventName}</h1>
            <h2>{post.eventDatum}</h2>
            <div className={styles.body}>
              {post.eventDescription?.raw && renderRichText(post.eventDescription)}
            </div>
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/evenementen/${previous.slug}`} rel="prev">
                        ← {previous.eventName}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/evenementen/${next.slug}`} rel="next">
                        {next.eventName} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default EventTemplate

export const pageQuery = graphql`
  query EventBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulEvenementData(slug: { eq: $slug }) {
      slug
      eventName
      eventDatum
      eventDescription{
        raw
      }
      eventImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
    }
    previous: contentfulEvenementData(slug: { eq: $previousPostSlug }) {
      slug
      eventName
    }
    next: contentfulEvenementData(slug: { eq: $nextPostSlug }) {
      slug
      eventName
    }
  }
`
